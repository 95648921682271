import { FC, PropsWithChildren, Suspense, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { APP_LANGUAGE_STORAGE_KEY, SUPPORTED_LANGUAGES } from 'configs/common';
import { LAYOUT_CONTENT_ID } from './configs';
import { useDispatch, useSelector } from 'store';
import { getSettingsSlice } from 'store/selectors';
import { setLanguage } from 'store/slices/settings';
import { useAuthenticatedInformation } from 'hooks';
import { getNavItems } from './helper';
import { ESupportedLanguages } from 'types/common';
import CustomLayoutCard from 'components/CustomLayoutCard';
import Header from 'components/Header';
import Nav from 'components/Nav';
import Spinner from 'components/Spinner';
import styles from './Layout.module.css';
import { ConfigProvider } from 'antd';

export const Layout: FC<PropsWithChildren> = ({ children = <Outlet /> }) => {
	const { user } = useAuthenticatedInformation();
	const allowedRoutes = useMemo(() => user.allowedRoutes, [user]);
	const navItems = useMemo(() => getNavItems(allowedRoutes), [allowedRoutes]);
	const dispatch = useDispatch();
	const { t: tCommon } = useTranslation('common');

	const formValidateMessages = {
		required: tCommon('form_validations.required_field'),
	};

	// ! selectors
	const { language: currentAppLanguage } = useSelector(getSettingsSlice);
	const languageConfig = SUPPORTED_LANGUAGES[currentAppLanguage as ESupportedLanguages];

	const direction = useMemo(() => languageConfig?.defaultDirection ?? 'ltr', [languageConfig?.defaultDirection]);

	// ! useEffects
	useEffect(() => {
		dispatch(
			setLanguage(
				(localStorage.getItem(APP_LANGUAGE_STORAGE_KEY) as ESupportedLanguages) ?? ESupportedLanguages.ENGLISH
			)
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// ! render
	return (
		<ConfigProvider
			direction={direction}
			locale={languageConfig.locale}
			form={{ validateMessages: formValidateMessages }}
		>
			<div className={`${styles.grid_wrapper} ${direction}`}>
				<div className={styles.header}>
					<Header />
				</div>
				<div className={styles.sidebar}>
					<Nav items={navItems} />
				</div>
				<div
					className={styles.content}
					id={LAYOUT_CONTENT_ID}
				>
					{/* TODO @tlsltaken @galleonpt @nibsa @Trs-24 PAGE  CUSTOM CARD SHOULD BE REMOVED AND BE USED INSIDE OF EACH PAG? --> CHECK WITH PO TEAM & DESIGNERS FIRST */}
					<CustomLayoutCard style={{ height: '100%', overflow: 'auto' }}>
						<Suspense fallback={<Spinner />}>{children}</Suspense>
					</CustomLayoutCard>
				</div>
			</div>
		</ConfigProvider>
	);
};
